import React, { createContext, useState, useEffect, useCallback } from 'react';
import daynight from 'daynight';
import { defaultPrefs } from '../utils/defaults';
import { fetchUser, getMisc, loadFromLocalForage, mergePrefs, pushPrefs, updateMisc, updateUser } from '../utils/books';
import type { Prefs, SnackbarMessage, UserInfo } from '../types/book';
import throttle from 'lodash-es/throttle';
import { eventsUrl } from '../config';
import { version } from '../../package.json';
import { logger } from '../utils/logger';
import { checkVersion } from '../utils/api';
import { useOnlineStore } from '../store/onlineStore';
import semver from 'semver';

interface AppContextType {
  prefs: Prefs | undefined;
  changePrefs: (p: Partial<Prefs>) => void;
  effectiveTheme: 'light' | 'dark' | undefined;
  snackbarMessage: SnackbarMessage | null;
  setSnackbarMessage: (m: SnackbarMessage) => void;
  isOnline: boolean | null;
  setOnline: (o: boolean) => void;
  checkAppVersion: () => void;
  localForageLoaded: boolean;
  user: UserInfo | null | undefined;
  setUser: (u: UserInfo | null | undefined) => void;
}

export const AppContext = createContext<AppContextType>({
  prefs: undefined,
  changePrefs: () => {},
  effectiveTheme: undefined,
  snackbarMessage: null,
  setSnackbarMessage: () => {},
  isOnline: true,
  setOnline: () => {},
  checkAppVersion: () => {},
  localForageLoaded: false,
  user: undefined,
  setUser: () => {},
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [prefs, setPrefs] = useState<Prefs>();
  const [effectiveTheme, setEffectiveTheme] = useState<'light' | 'dark' | undefined>();
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>(null);
  const { isOnline, setOnline } = useOnlineStore();
  const [localForageLoaded, setLocalForageLoaded] = useState(false);
  const [user, setUser] = useState<UserInfo | null | undefined>();

  useEffect(() => {
    const startup = async () => {
      if (!localForageLoaded) {
        const loaded = await loadFromLocalForage();
        if (!localForageLoaded) {
          const user = await fetchUser();
          if (user !== undefined) {
            setUser(user);
            const prefs = user?.prefs || defaultPrefs;
            setPrefs(prefs);
            setEffectiveTheme(
              prefs.theme === 'light' ? 'light' : prefs.theme === 'dark' ? 'dark' : daynight().light ? 'light' : 'dark'
            );
            setLocalForageLoaded(loaded);
            console.debug('startup load finished, user =', user, ' prefs =', prefs);
          } else {
            console.debug('startup load failed');
          }
        }
      }
    };
    startup();
  }, [localForageLoaded]);

  useEffect(() => {
    const updateUser = async () => {
      const user = await fetchUser(true);
      if (user !== undefined) {
        setUser(user);
        const prefs = user?.prefs || defaultPrefs;
        setPrefs(prefs);
        setEffectiveTheme(
          prefs.theme === 'light' ? 'light' : prefs.theme === 'dark' ? 'dark' : daynight().light ? 'light' : 'dark'
        );
        console.debug('startup remote fetch finished, user =', user, ' prefs =', prefs);
      } else {
        console.debug('startup remote fetch failed');
      }
    };
    if (localForageLoaded) {
      updateUser();
    }
  }, [localForageLoaded]);

  const updateEffectiveTheme = () => {
    if (localForageLoaded) {
      if (prefs?.theme === 'auto') {
        setEffectiveTheme(daynight().light ? 'light' : 'dark');
      } else if (prefs?.theme === 'light' || prefs?.theme === 'dark') {
        setEffectiveTheme(prefs?.theme);
      }
    }
  };

  useEffect(() => {
    // Push a new entry onto the history stack upon initial load
    const handleLogout = (event: CustomEvent): void => {
      logger.debug('detected logout in AppContext');
      //   setUser(null);
    };

    window.addEventListener('logout', handleLogout as EventListener);

    return () => window.removeEventListener('logout', handleLogout as EventListener);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(updateEffectiveTheme, 15 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [updateEffectiveTheme, prefs?.theme]);

  const throttledPushPrefs = throttle(pushPrefs, 5000);

  const changePrefs = (p: Partial<Prefs>) => {
    const newPrefs = mergePrefs(prefs, p);
    setPrefs(newPrefs);
    updateEffectiveTheme();
    updateUser({}, newPrefs);
    throttledPushPrefs();
  };

  useEffect(() => {
    if (localForageLoaded && prefs?.theme && effectiveTheme !== prefs?.theme) {
      updateEffectiveTheme();
    }
  }, [prefs?.theme, localForageLoaded, updateEffectiveTheme]);

  const connect = useCallback(() => {
    const eventSource = new EventSource(eventsUrl);
    eventSource.onmessage = (event) => {
      console.log('event:', event);
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'broadcast') {
          setSnackbarMessage({ text: data.text, duration: data.duration || 5000 });
        }
        setOnline(true);
      } catch (error) {
        console.error('Error parsing SSE message:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      eventSource.close();
      //   setSnackbarMessage({ text: 'Could not connect to server. It may be down for maintenance.', duration: null });
    };

    return eventSource;
  }, [eventsUrl, isOnline, setOnline]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }

    let eventSource: EventSource | null = null;

    eventSource = connect();

    return () => {
      eventSource?.close();
    };
  }, [isOnline, connect]);

  const checkAppVersion = useCallback(async () => {
    if (!localForageLoaded) {
      return;
    }
    logger.debug('Checking for app version update');
    const oldVersion = getMisc()?.version;
    console.debug('oldVersion read as', oldVersion, 'version read as', version);
    if (!oldVersion || (version && semver.neq(oldVersion, version))) {
      if (oldVersion && semver.lt(oldVersion, version)) {
        setSnackbarMessage({
          text: `Upgraded from ${oldVersion || 'something-or-other'} to ${version}.`,
          duration: 5000,
        });
      }
      updateMisc({ ...getMisc(), version: version });

      return;
    }

    const lastCheckKey = 'lastVersionCheck';
    const currentTime = Date.now();
    const lastCheckTime = getMisc()?.lastVersionCheck || 0;

    if (currentTime - lastCheckTime < 15 * 60 * 1000) {
      return;
    }

    try {
      const currentVersion = await checkVersion();

      if (currentVersion && semver.neq(version, currentVersion)) {
        setSnackbarMessage({ text: 'New version available. One moment...', duration: null });
        window.location.reload();
      }
    } catch (error) {
      logger.error('Failed to check version:', error);
    }
  }, [isOnline, version, setSnackbarMessage, localForageLoaded]);

  //   useEffect(() => {
  //     const updateOnlineStatus = () => {
  //       if (navigator.onLine !== isOnline) {
  //         setOnline(navigator.onLine);
  //       }
  //     };

  //     // Set initial online status
  //     updateOnlineStatus();

  //     // Add event listeners for online/offline events
  //     window.addEventListener('online', updateOnlineStatus);
  //     window.addEventListener('offline', updateOnlineStatus);

  //     // Clean up event listeners on component unmount
  //     return () => {
  //       window.removeEventListener('online', updateOnlineStatus);
  //       window.removeEventListener('offline', updateOnlineStatus);
  //     };
  //   }, [isOnline, setOnline]);

  return (
    <AppContext.Provider
      value={{
        prefs,
        changePrefs,
        effectiveTheme,
        snackbarMessage,
        setSnackbarMessage,
        isOnline,
        setOnline,
        checkAppVersion,
        localForageLoaded,
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
