import ReactDOM from 'react-dom/client';
import App from './App';
import { setViewport } from './utils/viewports';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(`${import.meta.env.BASE_URL}service-worker.js`)
      .then((registration) => {
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
window.addEventListener('vite:preloadError', (event) => {
  window.location.reload(); // for example, refresh the page
});

setViewport();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
