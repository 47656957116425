import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  DarkMode as DarkModeIcon,
  ExpandMore as ExpandMoreIcon,
  FormatAlignJustify as FormatAlignJustifyIcon,
  FormatAlignLeft as FormatAlignLeftIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  LightMode as LightModeIcon,
  SwapHoriz as SwapHorizIcon,
  SwapVert as SwapVertIcon,
  Brightness6 as Brightness6Icon,
} from '@mui/icons-material';
import { getFontList } from '../fonts.config';
import type { BookSettings } from '../types/book';
import { isIOS, isMobileOnly } from 'react-device-detect';
import { AppContext } from '../context/AppContext';

interface SettingsComponentProps {
  settings: BookSettings;
  updateSettings: (settings: BookSettings, progress?: number) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}

const SettingsComponent: React.FC<SettingsComponentProps> = ({ updateSettings, settings, onDragStart, onDragEnd }) => {
  const progress = settings.progress;
  const googleFonts = getFontList();
  const { prefs, changePrefs, effectiveTheme } = useContext(AppContext);

  const handleFontSizeChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        fontSize: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleLineSpacingChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        lineSpacing: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleMarginChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        margin: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newValue: string | string[]) => {
    updateSettings({
      alignment: Array.isArray(newValue) ? newValue[0] : newValue,
    });
  };

  const handleFontWeightChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        fontWeight: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleFlowChange = () => {
    updateSettings({ flow: settings.flow === 'scrolled' ? 'paginated' : 'scrolled' }, progress);
    // Transition to 0 opacity over 500ms
    document.body.style.transition = 'opacity 500ms ease-out';
    document.body.style.opacity = '0';
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleFullscreenChange = () => {
    const newFullscreen = !prefs?.fullscreen;
    changePrefs({ fullscreen: newFullscreen });
  };

  const handleThemeChange = () => {
    const newTheme = prefs?.theme === 'light' ? 'dark' : prefs?.theme === 'dark' ? 'auto' : 'light';
    changePrefs({ theme: newTheme });
  };

  const handleSaveSettings = () => {
    // Destructure and filter out unwanted properties
    const { updates, showNotes, status, progress, location, ...favoriteSettings } = settings;

    console.debug('handleSaveFavorite', favoriteSettings);
    changePrefs({ favorite: favoriteSettings });
  };

  const handleLoadSettings = () => {
    if (prefs?.favorite) {
      updateSettings({ ...prefs.favorite });
    }
  };

  const sliderSettings = [
    {
      label: 'Margin',
      value: settings.margin,
      onChange: handleMarginChange,
      min: -1,
      max: 20,
      step: 1,
      defaultValue: -1,
    },
    {
      label: 'Line spacing',
      value: settings.lineSpacing,
      onChange: handleLineSpacingChange,
      min: 0.95,
      max: 2.0,
      step: 0.05,
      defaultValue: 0.95,
    },
    {
      label: 'Paragraph spacing',
      value: settings.marginY === undefined ? -0.1 : settings.marginY,
      onChange: (_: Event, newValue: number | number[]) => updateSettings({ marginY: newValue as number }),
      min: -0.1,
      max: 1,
      step: 0.1,
      defaultValue: -0.1,
    },
    {
      label: 'Paragraph indent',
      value: settings.indent === undefined ? -0.25 : settings.indent,
      onChange: (_: Event, newValue: number | number[]) => updateSettings({ indent: newValue as number }),
      min: -0.25,
      max: 3,
      step: 0.25,
      defaultValue: -0.25,
    },
    {
      label: 'Font weight',
      value: settings.fontWeight,
      onChange: handleFontWeightChange,
      min: 0,
      max: 800,
      step: 100,
      defaultValue: 0,
    },
  ];

  const alignmentButtons = () => {
    return (
      <ToggleButtonGroup
        value={settings.alignment}
        exclusive
        onChange={handleAlignmentChange}
        aria-label='text alignment'
      >
        <ToggleButton value='left' aria-label='left aligned'>
          <FormatAlignLeftIcon />
        </ToggleButton>
        <ToggleButton value='justify' aria-label='justified'>
          <FormatAlignJustifyIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant='body1' sx={{ marginTop: '0em', textAlign: 'right' }}>
        Font size
      </Typography>
      <Slider
        aria-label='Font size'
        value={settings.fontSize}
        getAriaValueText={(value) => `${value}`}
        valueLabelDisplay='on'
        valueLabelFormat={(value) => (value <= 11 ? 'publisher' : value.toString())}
        step={1}
        min={11}
        max={30}
        onChange={handleFontSizeChange}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
      />

      <Accordion
        variant='outlined'
        sx={{
          backgroundColor: 'transparent',
          elevation: 0,
          boxShadow: 'none',
          my: 2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='additional-settings-content'
          id='additional-settings-header'
        >
          <Typography>Layout</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ border: 'none', pl: 6, pr: 2 }}>
          {sliderSettings.map((setting, index) => (
            <React.Fragment key={index}>
              <Typography variant='body1' sx={{ textAlign: 'right' }}>
                {setting.label}
              </Typography>
              <Slider
                aria-label={setting.label}
                value={setting.value}
                onChange={setting.onChange}
                step={setting.step}
                min={setting.min}
                max={setting.max}
                valueLabelDisplay='on'
                valueLabelFormat={(value) =>
                  value === setting.defaultValue ? 'publisher' : Math.round(value * 100) / 100
                }
                onMouseDown={onDragStart}
                onMouseUp={onDragEnd}
                onTouchStart={onDragStart}
                onTouchEnd={onDragEnd}
              />
            </React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>

      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id='font-family-select-label' variant='outlined'>
          Font
        </InputLabel>
        <Select
          labelId='font-family-select-label'
          id='font-family-select'
          value={settings.fontFamily}
          label='Font'
          onChange={(e) => updateSettings({ fontFamily: e.target.value as string })}
        >
          {googleFonts.map((font) => (
            <MenuItem key={font} value={font} sx={{ fontFamily: font, fontSize: '18px' }}>
              {font}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid container my={2}>
        <Grid item xs={12}>
          <Stack direction='row' spacing={2} justifyContent='space-between'>
            {alignmentButtons()}
            <ToggleButton value={settings.flow || 'paginated'} onChange={handleFlowChange}>
              {settings.flow === 'paginated' ? <SwapHorizIcon /> : <SwapVertIcon />}
            </ToggleButton>
            <ToggleButton
              disabled={isIOS && isMobileOnly}
              value={prefs?.fullscreen || false}
              onChange={handleFullscreenChange}
            >
              {prefs?.fullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
            </ToggleButton>
            <ToggleButton value={prefs?.theme || 'light'} onChange={handleThemeChange}>
              {prefs?.theme === 'light' ? (
                <LightModeIcon />
              ) : prefs?.theme === 'dark' ? (
                <DarkModeIcon />
              ) : (
                <Brightness6Icon />
              )}
            </ToggleButton>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
        <Typography sx={{ alignContent: 'center' }}>Favorite settings</Typography>
        <Button variant='contained' color='primary' onClick={handleLoadSettings}>
          Load
        </Button>
        <Button variant='contained' color='secondary' onClick={handleSaveSettings}>
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default SettingsComponent;
