import { version } from '../../package.json';
import { useOnlineStore } from '../store/onlineStore';

/**
 * Checks the current version against the server version.
 * @returns {Promise<string | undefined>} The current version of the server, or undefined on error.
 * @async
 */
const checkVersion = async (): Promise<string | undefined> => {
  try {
    const response = await fetch('/api/check_version', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ version: version, dev: import.meta.env.DEV }),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    useOnlineStore.setState({ isOnline: true });

    const data = await response.json();
    const currentVersion = data.version;
    return currentVersion;
  } catch (error) {
    useOnlineStore.setState({ isOnline: false });
    console.error('Error checking version:', error);
    return;
  }
};

export { checkVersion };
