import React from 'react';
import { IconButton, type IconButtonProps } from '@mui/material';
import { FiberManualRecord as FiberManualRecordIcon, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';

interface DotButtonProps extends IconButtonProps {
  selected: boolean;
  outline?: boolean;
}

export const DotButton: React.FC<DotButtonProps> = ({ selected, outline = false, ...props }) => (
  <IconButton
    {...props}
    size='small'
    sx={{
      color: '#1976d2',
    }}
  >
    {selected ? <FiberManualRecordIcon fontSize='small' /> : <FiberManualRecordOutlinedIcon fontSize='small' />}
  </IconButton>
);

interface NavButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const PrevButton: React.FC<NavButtonProps> = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    aria-label='Previous'
    sx={{
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#1976d2',
      visibility: disabled ? 'hidden' : 'visible',
    }}
    disabled={disabled}
  >
    <ArrowBackIos />
  </IconButton>
);

export const NextButton: React.FC<NavButtonProps> = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    aria-label='Next'
    sx={{
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#1976d2',
      visibility: disabled ? 'hidden' : 'visible',
    }}
    disabled={disabled}
  >
    <ArrowForwardIos />
  </IconButton>
);
