import { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Stack,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon, Share as ShareIcon, Search as SearchIcon } from '@mui/icons-material';

interface RevoResult {
  drv: string; // The HTML content of the <div class="drv">
  wrappedDrv: string; // The HTML content with words wrapped in spans
}

interface DictionaryViewProps {
  open: boolean;
  onClose: () => void;
  word: string;
}

export const RevoDictionaryView = ({ open, onClose, word }: DictionaryViewProps) => {
  const [revoResults, setRevoResults] = useState<RevoResult[]>([]);
  const dialogContentRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(word);
  const [filteredDrv, setFilteredDrv] = useState<string | null>(null);

  const handleClose = () => {
    setLoading(false);
    setInputValue('');
    setRevoResults([]);
    setFilteredDrv(null);
    onClose();
  };

  const handleRevoLookup = async (word: string) => {
    const url = `/api/search_revo`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query: word }),
    };

    setLoading(true);
    setFilteredDrv(null); // Reset the filter here

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.results && Array.isArray(data.results)) {
        setRevoResults(
          data.results.map((drv: string) => ({
            drv,
            wrappedDrv: wrapWordsInSpans(drv),
          }))
        );
        // Update the input value with the effective query
        if (data.effective_query) {
          setInputValue(data.effective_query);
        }
      } else {
        setRevoResults([]);
      }
    } catch (error) {
      console.error('Error fetching Revo data:', error);
      setRevoResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleShareToAnki = () => {
    const share = async () => {
      if (dialogContentRef.current) {
        let htmlToShare = '';
        if (filteredDrv) {
          htmlToShare = filteredDrv.replace(/\s+/g, ' ');
        } else if (revoResults.length > 0) {
          htmlToShare = revoResults.map((result) => result.drv.replace(/\s+/g, ' ')).join('\n\n');
        }

        try {
          await navigator.share({
            text: htmlToShare,
            title: inputValue,
          });
        } catch (err) {
          console.error('Failed to share:', err);
        }
      }
    };
    share();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const wrapWordsInSpans = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const elements = doc.querySelectorAll('.dif, .trd, .kap, .ekz'); // Only wrap in .dif and .kap

    elements.forEach((element) => {
      const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null);
      const nodesToReplace: Node[] = [];

      while (walker.nextNode()) {
        nodesToReplace.push(walker.currentNode);
      }

      nodesToReplace.forEach((node) => {
        const words = node.textContent?.split(/\s+/) || [];
        const spanWrappedWords = words
          .map((word) => (word.length > 3 ? `<span class="clickable-word">${word}</span>` : word))
          .join(' ');
        const spanWrappedFragment = document.createRange().createContextualFragment(spanWrappedWords);

        node.parentNode?.replaceChild(spanWrappedFragment, node);
      });
    });

    return doc.body.innerHTML;
  };

  const handleWordClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.classList.contains('clickable-word')) {
      const clickedWord = target.textContent?.trim();
      if (clickedWord) {
        const cleanWord = clickedWord
          .replace(/[^a-zA-ZĉĈĥĤĵĴŝŜŭŬĝĜàâäæçéèêëîïôœùûüÿÀÂÄÆÇÉÈÊËÎÏÔŒÙÛÜŸ\-\'\’]/g, '')
          .toLowerCase();
        if (cleanWord) {
          if (target.closest('.kap')) {
            // If clicked word is in .kap, filter but don't search
            const clickedDrv = target.closest('.drv');
            if (clickedDrv) {
              const drvHtml = revoResults.find((result) => result.wrappedDrv.includes(clickedDrv.outerHTML))?.drv;
              if (drvHtml) {
                setFilteredDrv(drvHtml);
                setInputValue(cleanWord);
              }
            }
          } else {
            // Otherwise, perform a new search
            setInputValue(cleanWord);
            handleRevoLookup(cleanWord);
          }
        }
      }
    }
  };

  useEffect(() => {
    setInputValue(word);
    if (open && word) {
      handleRevoLookup(word);
    }
  }, [open, word]);

  useEffect(() => {
    if (revoResults.length > 0 && dialogContentRef.current) {
      const contentElement = dialogContentRef.current;
      contentElement.addEventListener('click', handleWordClick);

      return () => {
        contentElement.removeEventListener('click', handleWordClick);
      };
    }
  }, [revoResults]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Stack direction='row' gap={1}>
          <TextField
            fullWidth
            variant='outlined'
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter') {
                handleRevoLookup(inputValue);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title='Press Enter to lookup word'>
                    <IconButton onClick={() => handleRevoLookup(inputValue)} aria-label='lookup'>
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {(revoResults.length > 0 || filteredDrv) && (
            <IconButton onClick={handleShareToAnki} aria-label='share' edge='end'>
              <ShareIcon />
            </IconButton>
          )}
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant='caption' marginLeft='1.5em'>
          {revoResults.length === 0 && !loading && 'No results found in ReVo'}
        </Typography>
      </DialogTitle>
      <DialogContent ref={dialogContentRef}>
        {loading ? (
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        ) : filteredDrv ? (
          <div style={{ overflowX: 'hidden' }} dangerouslySetInnerHTML={{ __html: filteredDrv }} />
        ) : (
          revoResults.length > 0 && (
            <div style={{ overflowX: 'hidden' }}>
              {revoResults.map((result, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: result.wrappedDrv }} // Use wrappedDrv here
                />
              ))}
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
