import React, { useEffect, useState, useRef } from 'react';
import {
  TextField,
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  CircularProgress,
  useTheme,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Note as NoteIcon,
  Undo as UndoIcon,
  AddBox as AddBoxIcon,
  AddReaction as AddReactionIcon,
} from '@mui/icons-material';
import type { Note, NoteComment, NoteCommentGroup, SnackbarMessage } from '../types/book';
import { getCommentList, getCurrentUser, getDisplayName, getPerson, getTheme, setNoteStatus } from '../utils/books';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MarkdownImage from './MarkdownImage';
import { aiUser, highlightColors } from '../config';
import { fetchGeepersData } from '../utils/books';
import EmojiPicker, { EmojiStyle, SuggestionMode, Theme } from 'emoji-picker-react';
import { logger } from '../utils/logger';

interface NoteBoxProps {
  note: Note;
  onClose: () => void;
  onSave: (note: Note) => void;
  onDelete: () => void;
  editable: boolean;
  setSnackbarMessage: (message: SnackbarMessage) => void;
  getContextFunction?: (selection: Selection) => string;
  selection?: Selection | null;
}

// interface PopoverVirtualElement {
//     nodeType: 1;
//     getBoundingClientRect: () => DOMRect;
//   }

//   const getVirtualElement = (note: Note) => {
//     const anchorEl = document.querySelector(`svg > g[data-id="${note.id}"]`);
//     if (anchorEl) {
//       return anchorEl as PopoverVirtualElement;
//     }
//     return null;
//   };

const NoteBox: React.FC<NoteBoxProps> = ({
  note,
  onClose,
  onSave,
  onDelete,
  editable,
  setSnackbarMessage,
  getContextFunction,
  selection,
}) => {
  const [allowChange, setAllowChange] = useState(false);
  const [ownerName, setOwnerName] = useState<string>();
  const [comments, setComments] = useState<NoteCommentGroup>(note.comments || {});
  const [commentList, setCommentList] = useState<NoteComment[]>(getCommentList(note.comments));
  const [newComment, setNewComment] = useState('');
  const [allowInteraction, setAllowInteraction] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const buttonSize = 48;
  const buttonMargin = 0;
  const [isClosing, setIsClosing] = useState(false);
  const colors = highlightColors;
  const theme = useTheme();
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | undefined>();

  const commentInputRef = useRef<HTMLInputElement>(null);
  const initialScrollTop = useRef<number | undefined>();

  useEffect(() => {
    if (initialScrollTop.current === undefined) {
      initialScrollTop.current = document.querySelector('.epub-container')?.scrollTop;
    }
  }, [initialScrollTop]);

  useEffect(() => {
    const anchorEl = document.querySelector(`svg > g[data-id="${note.id}"]`);
    if (!anchorPosition && anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      setAnchorPosition({
        // top: rect.top + window.scrollY + rect.height,
        top: Math.min(rect.top + window.scrollY, window.innerHeight - 320),
        left: window.scrollX + window.innerWidth / 2,
      });
    }
  }, [anchorPosition, note.id]);

  useEffect(() => {
    if (anchorPosition) {
      setAllowInteraction(false);
      document.body.style.userSelect = 'none';
      const timer = setTimeout(() => {
        setAllowInteraction(true);
        document.body.style.userSelect = '';
      }, 300);
      return () => {
        clearTimeout(timer);
        document.body.style.userSelect = '';
      };
    }
  }, [anchorPosition]);

  useEffect(() => {
    if (note.id) {
      setNoteStatus(note.id, {
        read: true,
      });
    }
  }, [note.id]);

  useEffect(() => {
    const fetchDisplayName = async () => {
      if (!editable && note['owner']) {
        const displayName = await getDisplayName(note['owner']);
        setOwnerName(displayName);
      }
    };

    fetchDisplayName();
  }, [editable, note['owner']]);

  const handleColorChange = (event: React.MouseEvent<HTMLElement>, newColor: string | null) => {
    if (editable && newColor) {
      onSave({ ...note, color: newColor });
    }
  };

  useEffect(() => {
    setCommentList(getCommentList(comments));
  }, [comments]);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      const isAiQuery = newComment.toLowerCase().includes('@ai');
      const newCommentObj: NoteComment = {
        text: newComment,
        owner: getCurrentUser()?.email || '',
        timestamp: new Date().toISOString(),
        // TODO: use previous, if editing
        id: crypto.randomUUID(),
        reactions: {},
      };
      const updatedComments = {
        ...comments,
        [newCommentObj.id]: newCommentObj,
      };
      setComments(updatedComments);
      onSave({ ...note, comments: updatedComments });
      setNewComment('');

      if (isAiQuery) {
        await handleAiQuery(newCommentObj, updatedComments);
      }
    }
  };

  const handleAddReaction = (emoji: string) => {
    const userEmail = getCurrentUser()?.email;
    if (emoji && userEmail) {
      const updatedReactions = { ...note.reactions };
      if (updatedReactions[userEmail] === emoji) {
        delete updatedReactions[userEmail];
      } else {
        updatedReactions[userEmail] = emoji;
      }
      onSave({ ...note, reactions: updatedReactions });
      logger.debug('Reaction updated', { noteId: note.id, userEmail, emoji });
    } else {
      logger.error('Reaction not updated', { noteId: note.id, userEmail, emoji });
    }
  };

  const handleAiQuery = async (userComment: NoteComment, updatedComments: NoteCommentGroup) => {
    if (!getContextFunction || !selection) {
      setSnackbarMessage({
        text: "Missing text or context. This shouldn't happen. You're the first one to find this bug!",
        duration: 10000,
      });
      console.error('Missing text or context, note.id: ', note.id);
      return;
    }
    const context = getContextFunction(selection);
    setIsAiLoading(true);
    try {
      const aiQuery = userComment.text
        .replace(/@ai/gi, '')
        .replace(/\s+/g, ' ')
        .replace(/\#show/gi, '')
        .replace(/\s+/g, ' ')
        .trim();
      const response = await fetchGeepersData({
        command: 'tag',
        text: note.text,
        context: { default: context },
        query: aiQuery,
      });
      console.debug('response', response);

      const aiResponse: NoteComment = {
        text: response,
        owner: aiUser.email,
        timestamp: new Date().toISOString(),
        id: crypto.randomUUID(),
        reactions: {},
        read: true,
      };

      const newUpdatedComments = {
        ...updatedComments,
        [aiResponse.id]: aiResponse,
      };

      setComments(newUpdatedComments);
      onSave({ ...note, comments: newUpdatedComments });
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setSnackbarMessage({
        text: 'Sorry, no luck contacting the AI. Try again later.',
        duration: 5000,
      });
    } finally {
      setIsAiLoading(false);
    }
  };

  const handleEditLastComment = () => {
    if (
      commentList.length > 0 &&
      (!commentList[commentList.length - 1].owner ||
        commentList[commentList.length - 1].owner === getCurrentUser()?.email ||
        (commentList[commentList.length - 1].owner === aiUser.email && (note.owner === getCurrentUser()?.email || '')))
    ) {
      const id = commentList[commentList.length - 1].id;
      setNewComment(comments[id].text);
      const updatedComments = { ...comments };
      updatedComments[id].deleted = true;
      setComments(updatedComments);
      onSave({ ...note, comments: updatedComments });
    } else if (commentList.length == 0 && getCurrentUser()?.email === note.owner) {
      onDelete();
    }
  };
  const handleClose = (event: React.SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!allowInteraction) {
      return;
    }
    // if (reason === 'backdropClick') {
    //   return;
    // }
    setIsClosing(true);
    const container = document.querySelector('.epub-container');
    const newScrollTop = container?.scrollTop;
    console.log('newScrollTop', newScrollTop);
    console.log('initialScrollTop', initialScrollTop.current);
    if (newScrollTop !== initialScrollTop.current) {
      container?.scrollTo({ top: initialScrollTop.current, behavior: 'instant' });
    }
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Adjust this value to match your transition duration
  };

  useEffect(() => {
    const container = document.querySelector('.epub-container');
    const handleScroll = () => {
      const anchorEl = document.querySelector(`svg > g[data-id="${note.id}"]`);
      if (anchorEl) {
        const rect = anchorEl.getBoundingClientRect();
        setAnchorPosition({
          top: Math.min(rect.top + window.scrollY, window.innerHeight - 320),
          left: window.scrollX + window.innerWidth / 2,
        });
      }
      //   if (container && initialScrollTop.current !== undefined) {
      //     container.scrollTop = initialScrollTop.current;
      //   }
    };
    container?.addEventListener('scroll', handleScroll);
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [initialScrollTop]);

  return (
    anchorPosition && (
      <Popover
        open={Boolean(anchorPosition)}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={anchorPosition}
        // disableScrollLock={true}
        disablePortal={true}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        elevation={8}
        slotProps={{
          paper: {
            style: {
              animation: isClosing ? 'noteOut 300ms ease-in-out' : 'noteIn 300ms ease-in-out',
              position: 'fixed',
              opacity: isClosing ? 0 : 1,
              maxWidth: '320px',
              maxHeight: '640px',
              pointerEvents: allowInteraction ? 'auto' : 'none',
              userSelect: allowInteraction ? 'auto' : 'none',
              padding: '16px',
              backgroundColor: getTheme() === 'dark' ? '#082b30' : '#f0f0e0',
              border: getTheme() === 'dark' ? '1px solid #333' : 'none',
              borderRadius: '4px 6px 4px 2px',
            },
          },
        }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Avatar
            style={{
              width: 40,
              height: 40,
              marginRight: 8,
            }}
            src={getPerson(note.owner || '')?.picture}
          >
            <NoteIcon />
          </Avatar>
          <ToggleButtonGroup
            value={note.color}
            exclusive
            onChange={handleColorChange}
            aria-label='color selection'
            style={{
              marginRight: '-4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'right',
            }}
          >
            {colors.map((color) => (
              <ToggleButton
                key={color}
                value={color}
                aria-label={color}
                style={{
                  padding: '0px',
                  margin: '4px',
                  backgroundColor: color,
                  height: '40px',
                  width: '40px',
                  borderRadius: '25%',
                }}
              ></ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box mt={2}>
          {commentList.map((comment, index) => (
            <React.Fragment key={comment.id}>
              {index < commentList.length && <Divider />}

              <ListItem alignItems='flex-start' sx={{ p: 0, py: 1, m: 0 }}>
                <Stack alignItems='center' alignSelf='flex-start' sx={{ mr: 2 }}>
                  <ListItemAvatar sx={{ my: 1, minWidth: 40 }}>
                    <Avatar
                      src={comment.owner === '' ? getCurrentUser()?.picture : getPerson(comment.owner || '')?.picture}
                    >
                      <NoteIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <Typography
                    sx={{
                      opacity: 0.6,
                      fontSize: 'small',
                      textAlign: 'center',
                    }}
                    variant='body2'
                    color='text.primary'
                  >
                    {`${new Intl.DateTimeFormat('en-US', {
                      month: 'numeric',
                      day: 'numeric',
                    }).format(new Date(comment.timestamp))}`}
                  </Typography>
                  <Typography
                    sx={{
                      opacity: 0.4,
                      fontSize: 'smaller',
                      textAlign: 'center',
                    }}
                    variant='body2'
                    color='text.primary'
                  >
                    {`${new Intl.DateTimeFormat('en-US', {
                      minute: '2-digit',
                      hour: '2-digit',
                      hourCycle: 'h24',
                    }).format(new Date(comment.timestamp))}`}
                  </Typography>
                </Stack>

                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize: comment.text.length > 100 ? '0.75em' : comment.text.length > 20 ? '1em' : '1.25em',
                    },
                  }}
                  primary={
                    <>
                      {commentList.length > 0 &&
                        (!commentList[commentList.length - 1].owner ||
                          commentList[commentList.length - 1].owner === getCurrentUser()?.email ||
                          (commentList[commentList.length - 1].owner === aiUser.email &&
                            (note.owner === getCurrentUser()?.email || ''))) &&
                        index === commentList.length - 1 && (
                          <IconButton
                            onClick={handleEditLastComment}
                            color='secondary'
                            sx={{
                              float: 'right',
                              width: '24px',
                              height: '24px',
                              margin: '0',
                              color: '#b44',
                              border: '1px solid #b44',
                              borderRadius: '5px',
                              marginLeft: '4px',
                            }}
                          >
                            <UndoIcon
                              sx={{
                                m: 0,
                                p: 0,
                                fontSize: '18px',
                              }}
                            />
                          </IconButton>
                        )}
                      <Markdown
                        className='markdown notebox'
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          img: (props) => <MarkdownImage {...props} />,
                        }}
                      >
                        {comment.text}
                      </Markdown>
                    </>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
          {isAiLoading && (
            <ListItem alignItems='flex-start' sx={{ p: 0, py: 1, m: 0 }}>
              <Stack alignItems='center' alignSelf='flex-start' sx={{ mr: 2 }}>
                <ListItemAvatar sx={{ my: 1, minWidth: 40 }}>
                  <Avatar src={aiUser.picture}>
                    <NoteIcon />
                  </Avatar>
                </ListItemAvatar>
              </Stack>
              <ListItemText
                primary={
                  <Box display='flex' alignItems='center'>
                    <CircularProgress size={20} sx={{ mr: 2 }} />
                    <Typography variant='body2'>Thinking...</Typography>
                  </Box>
                }
              />
            </ListItem>
          )}
        </Box>
        <Box mt={1} mb={1} display='flex' justifyContent='space-between' alignItems='center'>
          {commentList.length === 0 && note.owner === (getCurrentUser()?.email || '') && (
            <IconButton
              onClick={handleEditLastComment}
              color='secondary'
              sx={{
                padding: '0',
                width: buttonSize,
                height: buttonSize,
                margin: buttonMargin,
                color: '#888',
              }}
            >
              <DeleteIcon sx={{ m: 0, p: 0, fontSize: buttonSize * 0.8 }} />
            </IconButton>
          )}
          <TextField
            InputProps={{
              style: { padding: '8px' },
              inputRef: commentInputRef,
            }}
            fullWidth
            multiline
            variant='outlined'
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder='Add a comment...'
          />

          <Box flexGrow={1} />
          <IconButton
            onClick={handleAddComment}
            color='primary'
            disabled={!newComment.trim()}
            sx={{
              width: buttonSize,
              height: buttonSize,
              margin: buttonMargin,
            }}
          >
            <AddBoxIcon sx={{ ml: '12px', p: '0px', fontSize: buttonSize * 1 }} />
          </IconButton>
          {/* {note.reactions &&
              Object.entries(note.reactions).map(([emoji, users]) => (
                <Chip
                  key={emoji}
                  label={`${emoji} ${users.length}`}
                  onClick={() => handleAddReaction(emoji)}
                  style={{ margin: '2px' }}
                />
              ))} */}
          <IconButton
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            color='primary'
            sx={{
              width: buttonSize,
              height: buttonSize,
              margin: buttonMargin,
            }}
          >
            <AddReactionIcon sx={{ ml: '8px', p: '0px', fontSize: buttonSize * 0.6 }} />
          </IconButton>
        </Box>
        {showEmojiPicker && (
          <EmojiPicker
            theme={getTheme() === 'dark' ? Theme.DARK : Theme.LIGHT}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.APPLE}
            reactionsDefaultOpen={false}
            suggestedEmojisMode={SuggestionMode.RECENT}
            skinTonesDisabled={true}
            width={'100%'}
            height={'500px'}
            onEmojiClick={(emojiData) => {
              handleAddReaction(emojiData.emoji);
              setShowEmojiPicker(false);
            }}
          />
        )}
      </Popover>
    )
  );
};
export default NoteBox;
