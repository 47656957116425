/**
 * Sets the viewport meta tag based on the user agent.
 */
export const setViewport = () => {
  console.log('calling setViewport', navigator.userAgent);
  const existingViewport = document.querySelector('meta[name="viewport"]');
  const viewport = document.createElement('meta');
  if (existingViewport) {
    existingViewport.remove();
  }
  viewport.name = 'viewport';
  if (/Safari/i.test(navigator.userAgent) && !/Chrom/i.test(navigator.userAgent)) {
    viewport.content = 'width=device-width, initial-scale=1.0, user-scalable=yes, interactive-widget=overlays-content'; // iOS
  } else {
    viewport.content =
      'width=device-width, initial-scale=1.0, user-scalable=yes, viewport-fit=cover, interactive-widget=overlays-content';
  }
  document.head.appendChild(viewport);
};
